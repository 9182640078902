import { Container, Row, Col } from "react-bootstrap";
import { ArrowRightCircle } from "react-bootstrap-icons";
import portrait from '../assets/img/Portrait.png';
import { useEffect, useState } from "react";

export const Banner = () => {
    const [loopNum, setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const toRotate = ["problem solver", "programmer", "developer"];
    const [text, setText] = useState('');
    const [delta, setDelta] = useState(300-Math.random() * 100)
    const period = 2000;

    useEffect (() => {
        let ticker = setInterval(() => {
            tick();
        }, delta)

        return () => {clearInterval(ticker)};
    }, [text])

    const tick = () => {
        let i=loopNum % toRotate.length;
        let fullText = toRotate[i];
        let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

        setText(updatedText);

        if (isDeleting) {
            setDelta(prevDelta => prevDelta /2)
        }

        if (!isDeleting && updatedText===fullText) {
            setIsDeleting(true);
            setDelta(period);
        } else if (isDeleting && updatedText==='') {
            setIsDeleting(false);
            setLoopNum(loopNum + 1);
            setDelta(500);
        }
    }

    return (
        <section className="banner" id="home">
            <Container>
                <Row className="aligh-items-center">
                    <Col xs={12} md={6} xl={7}>
                        <span className="tagline">Welcome to my portfolio</span>
                        <h1>{"Hi I'm Ben Dyson "}<span className="wrap">{text}</span></h1>
                        <p>2nd Year Computer Scientist at the University of Manchester</p>
                        <button onClick={() => console.log("connect")}>Lets Connect<ArrowRightCircle size={25} /></button>
                    </Col>
                    <Col xs={12} md={6} xl={5}>
                        {/*Put header img here*/}
                        <img src={portrait} alt="header image" className="portraitImg"/>
                        {/* <img src={''} alt="header image" style={{ width: '10vw', height: '20vh' }} /> */}
                    </Col>
                </Row>
            </Container>

        </section>
    )
}